@import '../../common/css/variables.scss';

input.form-control
 {
  font-size: 1.5rem;
  height:auto;
}

.btn-primary {
  font-size: 1.25rem;
  padding: $vertical-spacing $horizontal-spacing*3;
}


.button-container {
  text-align:center;
}

.button-container p {
  margin-top: $vertical-spacing*2;
}

.form-group label {
  font-size: 1.1rem;
  margin-bottom: $vertical-spacing;
}

div.intro,p.intro {
  font-family: $default-font;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: $vertical-spacing*2;

}

.email {
  font-weight: 900;
  font-style: italic;
}

#no-email-reasons {
  padding: $vertical-spacing*2 $horizontal-spacing*2;
  margin-top: $vertical-spacing;
  background-color: #fff;
  font-size:1rem;
}

#no-email-reasons h5 {
  font-weight: bold;
}

#no-email-reasons .btn-link {
  font-size:1rem;
  margin-top: $vertical-spacing*2;
}

#password-field {
  display: table;
}

#password-field label,
#password-field .show-password-toggle {
  display: table-cell;
}

#password-field .show-password-toggle {
  text-align: right;
}

#password-field input {
  display: table-row;
}