/* FORMS */
@import './variables.scss';

.form-group {
  margin-bottom: $vertical-spacing*4;
}

button {
  font-family: $navigation-font;
  padding: $vertical-spacing $horizontal-spacing*2;
  font-size: 1rem;
  border-width: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.btn.btn-link {
  color: $link-color;
  padding:0;
  text-transform: none;
}

.btn-icon {
  padding:0;
  background-color: transparent;
  color: $link-color;
  text-transform:none;
}

.btn-icon:hover {
  text-decoration:underline;
  cursor: pointer;
}

.btn-icon:before
{
  font-family: 'FontAwesome';
  top: 0;
  left: 10px;
  margin-right: $horizontal-spacing/2;
  display:inline-block; /* for some reason this gets rid of the underline on the icon when you hover over the link */
}

.btn-edit:before {
  content: "\f044";
}

.btn-add:before {
  content: "\f0fe";
}

.btn-delete:before {
  content:"\f2ed"
}

.btn-edit:before {
  content: "\f044";
}

.knockout {
  color:#fff;
}

.btn-outline-primary {
  background-color: #fff;
  border-color: $clickable-border-color;
  color: $link-color;
}

.btn-outline-primary:hover {
  border-color: $highlight-link-color;
  color: #fff;
  background-color: $highlight-link-color;
}

.btn-primary {
  background-color: $link-color;
  color: #fff;
  border-color: $clickable-border-color;
}

.btn-primary:hover {
  background-color: $highlight-link-color;
  border-color: $highlight-link-color;
}

.button-container {
  margin-top: $vertical-spacing*4;
}

.button-container.previous-next  {
  display:flex;
}

.button-container.previous-next > div {
  text-align:right;
  width: 50%;
}

.button-container.previous-next > div:first-child {
  text-align:left;
}


.button-container.pinned {
  width:calc(100% + #{$horizontal-spacing*8});
  padding: 1rem;
  position:sticky;
  bottom:0;
  background-color:$dark-neutral-2;
  color: #fff;
  margin: 0 $horizontal-spacing*-4;
  padding: $vertical-spacing $horizontal-spacing*4;
  display:grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
}

.button-container.pinned .btn-link {
  color: #fff;
}

.button-container div:first-child {
  text-align:left;
}
.button-container div {
  text-align: right;
}


$radio-check-box-size: 1em;

.form-inline .input-group {
  display:grid;
  grid-template-columns: ($radio-check-box-size+($vertical-spacing*2)) auto;
}

.form-inline .input-group > div {
  padding: $vertical-spacing $horizontal-spacing;
  background-color: $light-neutral-1;
  box-sizing: border-box;
}

.form-inline .input-group > div > input {
  width: $radio-check-box-size;
  height: $radio-check-box-size;
}

.form-inline .input-group > input[type="text"] {
  height: 100%;
  box-sizing: border-box;
}


input {
  font-size: 1rem;
  padding: $vertical-spacing $horizontal-spacing;
}