@import '../css/variables.scss';

.alert {
  background-color: #fff;
  border-radius: 0;
  color: $dark-neutral-1;
  padding: 0;
  display:flex;
  align-items:center;
}

.alert i {
  color:#fff;
  background-color: $error-color;
  padding: $vertical-spacing $horizontal-spacing;
  font-size: 24px;
}

.alert p {
  margin: $vertical-spacing $horizontal-spacing;
}

.alert-danger {
  border-color: $error-color;
}

.alert-success {
  border-color: $success-color;
}
.alert-success  i{
  background-color: $success-color;
}

.alert-warning i {
  background-color: $warning-color;
}

.alert h1 {
  font-size: 1rem;
  margin: $vertical-spacing $horizontal-spacing;
}

.alert.inline {
  border-width:0;
  margin-bottom:0;
}
.alert.inline p {
  margin: 0;
  font-size:14px;
  line-height:14px;
}
.alert.inline i {
  font-size:14px;
  line-height:14px;
  padding:0 $horizontal-spacing/2 0 0;
  background-color:#fff;
}

.alert.inline.alert-success i {
  color: $success-color;
}
.alert.inline.alert-warning i {
  color: $warning-color;
}
.alert.inline.alert-danger i {
  color:  $error-color;
}