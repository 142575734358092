@import '../../common/css/variables.scss';
@import './quiz.scss';

.quiz-selection {
  border: 1px solid $standard-border-color;
}

.selecting .quiz-selection {
  border: 1px solid $clickable-border-color;
  border-radius: 8px;
}

.selecting .quiz-selection:hover {
  background-color: $highlight-link-color;
  cursor: pointer;
  color: #fff;
}

.quiz-selection.selected,
.quiz-selection.selected:hover,
.quiz-selection.selected:active
 {
  border-color: $active-color;
  color:$body-color;
  background-color: $highlight-background-color;
}

.quiz-selection.selected.correct,
.quiz-selection.correct
 {
  border-color: $success-color;
  background-color:#fff;

}

.quiz-selection.correct i {
  color: $success-color;
}

.quiz-selection.selected.incorrect {
  border-color: $error-color;
  background-color:#fff;
}

.quiz-selection.incorrect {
  border-color: $standard-border-color;
}

i {margin-right: $horizontal-spacing}

.quiz-question-result {
  background: $error-color;
  font-size: 14px;
} 


.quiz-item .button-container {
  text-align: center;
  background-color: $dark-neutral-2;  
  padding: $vertical-spacing*2 $horizontal-spacing*2;
  margin-top: 0;
}

.quiz-item .button-container .btn.btn-link {
  margin-left: $horizontal-spacing*2;
  background-color:transparent;
  color: #fff;
}

.button-container .btn.btn-link  {
  margin-right: $horizontal-spacing*2;
  background-color:transparent;
}

ul.inline li {
  display:inline;
  margin-right:$horizontal-spacing*2;
}

section.quiz-item {
  margin-top: $vertical-spacing*3;
}


.quiz-item > div {
  margin-top: $vertical-spacing*1;
}

.quiz-item > div:first-child {
  margin-top: 0;
}



