@import '../css/variables.scss';

.status .fa-check-circle {
  color: $success-color;
}

.status .fa-exclamation-circle {
  color: $error-color;
}

.callout section {
  margin-bottom:1em;
}

.callout h4 {
  text-transform: uppercase;
  font-size: .9rem;
}
