@import './variables.scss';


/* font sizing courtesy: 
 https://moderncss.dev/generating-font-size-css-rules-and-creating-a-fluid-type-scale/
 still need to implement support for smaller form factors
*/
  $type-base-size: 1rem;
  $type-size-ratio:  1.125; 
  $level-size: $type-base-size;

  // List in descending order to prevent extra sort function
  $type-levels: 4, 3, 2, 1;

  @each $level in $type-levels {
    $level-size: $level-size * $type-size-ratio;

    // Output heading styles
    // Assign to element and create utility class
    h#{$level} {
      font-size: $level-size;
      line-height: calc(2px + 2ex + 2px);
      margin-bottom: 0.65em;
      font-family: $heading-font;
    }
  }
/* end fluid font-sizine */


body {
  font-family: $default-font;
  font-size: $type-base-size;
  line-height: 1.5;
  
}

h1 span,h2 span {
  font-size: $type-base-size;
}

p {
  font-family: $copy-font;
  line-height: $type-base-size*3;
}

.no-results {
  font-style: italic;
}

label.field {
  font-weight: 700;
  color: $dark-neutral-1;
  margin-right: $horizontal-spacing;
}

label.heading {
  text-transform: uppercase;
  font-family: $label-font;
  display: block;
}


.count-correct {
  text-transform: uppercase;
  letter-spacing: .5px;
  text-align: center;
  font-size: $type-base-size;
}

.info-graphic {
  display:flex;
  align-items: center;

}

.info-graphic .main-stat {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 6rem; 
  font-weight: 900;
  line-height: auto;
}

.info-graphic .supporting .secondary {
  font-size: 2rem;
}

.info-graphic .supporting .tertiary {
  text-transform: uppercase;
}

i.fas {
  margin-right: $horizontal-spacing;
}

.callout p {
  font-family: $default-font;
}