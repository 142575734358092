@import '../../common/css/variables.scss';

#last-week-winner {
  display:flex;
  align-items: center;
  gap: $horizontal-spacing*2;
  margin-bottom: $vertical-spacing*2;
}

#last-week-winner i.fas {
  color: #FFF3B5;
  font-size: 4rem;
  width: 6rem;
  text-align: center;
  line-height: 6rem;
  background: #F7B500;
  border-radius: 3rem; /* or 50% width & line-height */
  margin-right:0;
}

#winner-name span {
  font-size:2.5rem;
  line-height: 2.5rem;
}
.standings-summary-row {
  display:flex;
  align-items: center;
  gap: $horizontal-spacing;
  margin-bottom: $vertical-spacing*2;
 
}



.rank {
  color: #555;
  width: 3rem;
  text-align: center;
  line-height: 3rem;
  background: #FFF3B5;
  border-radius: 1.5rem; /* or 50% width & line-height */
  margin-right:0;
  font-size:1.2rem;
}

.rank-user-name {
  font-size: 1rem;
}

.rank-user-name.displaying-1 {
  font-size:1.5rem;
} 

.rank.alt {
  color: #FFF3B5;
  background: #F7B500;
}

.games-behind {
  font-size:1rem;
}

.tied-with {
  font-size:.9rem;
}