@import '../../common/css/variables.scss';


.team-list {
  display: flex;
}

.team-logo {
  padding: $vertical-spacing/2 $horizontal-spacing/2;
  box-sizing: border-box;
}

.team-logo.selected,
.team-logo.clickable.selected:hover  {
  border: 2px solid $dark-neutral-1;
  border-radius: 50%;
  background-color: $light-neutral-2;
  cursor: default;
}

.team-logo.clickable {
  cursor: pointer;
}
.team-logo.clickable:hover {
  border: 1px solid $link-color;
  border-radius: 50%;
}

.team-logo img {
  width:100%;
  height: 100%;
}