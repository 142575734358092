@import '../css/variables.scss';

.user {
  line-height:2rem;
  vertical-align: middle;
}

.user i {
  margin-right: $horizontal-spacing/2;
  font-size: 2rem;
  vertical-align: middle;
  line-height:2rem;
}

.user a {
  height: 2rem;
  vertical-align: middle;
  line-height:2rem;
}