@import '../../common/css/variables.scss';

.day-container {
  border-bottom: 2px dotted $standard-border-color;
  margin-bottom: $vertical-spacing *2;
  padding-bottom: $vertical-spacing *2;
  
}

.games-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem 5rem;
}

.game-row {
  display:grid;
  align-items: center;
  grid-template-columns: 2fr 2fr 2fr;
}

.spread-container {
  text-align: center;
}

.spread-container .spread {
  color:$body-color;
  font-size: 1.8rem;
}

.spread-container input {
  text-align:center;
  font-size: 1.25rem;
}

.total-points-scored-value {
  color:$body-color;
  font-size: 1.8rem;
}

@media (max-width: 1024px) {  

  .games-list
   {
    grid-template-columns: 1fr;
    grid-gap: 0;
    align-items: center;
  }
  
}