@import './variables.scss';

$default-nav-width: 280px;

body {
  background-color:lighten($light-neutral-1,20%);
}


#logged-in-container {
  display: grid;
  grid-template-columns: $default-nav-width auto;
  grid-template-rows: 120px auto;
  height: 100vh;
  box-sizing: border-box;
}

header {
  background-color:#fff;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}

header .util {
  float: right;
  font-size: .9rem;
}

nav {
  background-color:#fff;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
}

main {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color:lighten($light-neutral-1,20%);
  box-sizing: border-box;
  padding: $vertical-spacing*4 $horizontal-spacing*4;
}

#nav-logo {
  max-width: $default-nav-width;
  width:100%;
}

#hamburger {
  display:none;
}
#nav-collapse {
  display: block;
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

@media screen and (max-width: 1200px) {
  #logged-in-container {
    grid-template-columns: 20% auto;
  }
  
}

@media screen and (max-width: 1024px) {
  $mobile-header-height: 75px;
  $mobile-nav-width: 275px;
  $nav-transition-duration: .5s;
  $container-padding: $horizontal-spacing*2;

  #logged-in-container {
    display: block;
  }

  header.navbar-vertical {
    width: 100vw;
    height: $mobile-header-height;
    display:grid; 
    grid-template-columns: 30px auto;
    grid-column-gap: $container-padding;
    align-items: center;
    vertical-align: middle;
    box-sizing: border-box;
    transition: margin-left $nav-transition-duration ease;
    padding-left: $container-padding;
  }

  header.expanded {
    position: absolute;
    top:0px;
    margin-left: $mobile-nav-width;
    //width: calc(100vw - #{$mobile-nav-width});
  }

  #hamburger {
    display:block;
    font-size:30px;
  }

  header > a {
    display:block;
    text-decoration: none
  }
  
  #nav-logo {
    max-height: 60px;
    width:auto;
  }

  nav {
    padding:0;
    width:0px;
    position:absolute;
    left: $mobile-nav-width*-1;
    transition: left $nav-transition-duration ease;
  }


  nav.expanded {
    display:inline-block;
    width: $mobile-nav-width;
    left:0;
  }

  .nav-section {
    padding: $container-padding;
  }

  .nav-section #primary-nav {
    margin: 0 $container-padding*-1;
  }

  #primary-nav li {
    padding-left: $container-padding;
    padding-right: $container-padding;
  }

  
  main {
    width: 100vw;
    display:block;
    position:absolute;
    left:0;
    transition: left $nav-transition-duration ease;
    padding: $container-padding;
  }

  main.expanded {
    left: $mobile-nav-width;
    position:absolute;
    top:$mobile-header-height;
  }

}

.grid-container {
  display: grid;
  grid-auto-rows: min-content;
  /* margin: 0 auto $vertical-spacing*6 auto; */
  grid-gap: $horizontal-spacing*5;
}

.grid-container.same-height {
  grid-auto-rows: 1fr;
}
.grid-container.same-height > div {
  display: table-cell
}

.grid-container.one-col {
  width:33%;
  grid-template-columns: 100%;
}

.grid-container.two-col {
  width:66%;
  grid-template-columns: 1fr 1fr;
}

.grid-container.three-col {
  width:100%;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-container.col-1-2 {
  width:100%;
  grid-template-columns: 1fr 2fr;
 
}

.grid-container.col-2-1 {
  width:100%;
  grid-template-columns: 2fr 1fr;
}

.grid-container.col-1-1 {
  width:100%;
  grid-template-columns: 1fr 1fr;
}

.grid-container.col-1-1-1 {
  width:100%;
  grid-template-columns: 1fr 1fr 1fr;
}





.week-list-container {
  margin-bottom: $vertical-spacing*2;
  padding: $vertical-spacing 0;
  border-top:1px solid $dark-neutral-2;
  border-bottom:1px solid $dark-neutral-2;
}

.week-list-container p {
  font-family: $default-font;
  font-size: .9rem;
}


.card {
  padding: $vertical-spacing*2 $horizontal-spacing*2;
  box-shadow: 3px 3px 3px #999;
  border-radius: 8px;
  background-color: #fff;
  max-width:500px;
  margin-bottom: $vertical-spacing*4;
}

.card .card-footer {
  background-color:$light-neutral-1;
  margin:$vertical-spacing*2 $horizontal-spacing*-2 $vertical-spacing*-2 $horizontal-spacing*-2;
  padding: $vertical-spacing*2 $horizontal-spacing*2; 
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid $standard-border-color;
  border-right: 1px solid $standard-border-color;
  text-align:center;
}

.card-footer > div {
  text-align: left;
}
.card-footer > div:first-child {
  text-align: right;
}

.card.disabled {
  background-color:$disabled-background-color;
  color:$disabled-text-color;
}

.card.disabled img {
  filter: grayscale(50%);
}

.card.disabled .alert,
.card.disabled .alert i {
  background-color:$disabled-background-color;
  color: $disabled-text-color;
}

.card.with-error {
  border: 2px solid $error-color;
}

.card .alert {
  margin-bottom: $vertical-spacing;
}

.page-utils {
  float:right;
  font-size: 14px;
  line-height:14px;
}
.page-utils .btn {
  font-size: 14px;
  line-height:14px;
}

@media (min-width: 768px) {
  /* this is a copy of bootstrap's offset-3 and col-6 */
  .page.page-slim {
    margin-left: 25%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.public-logo-container {
  width: 100%;
  text-align:center;
}

ul.status-report-list li.status {
  display:inline-block;
  box-sizing: border-box;
  width:25%; 
  padding-right: $horizontal-spacing*2;

  font-size: 1.2rem;
  margin-bottom: $vertical-spacing*4;  
}

ul.status-report-list li.status .status-detail {
  font-size: .9rem;
  text-transform:uppercase;
  color: #555;
}

.browser-version {
  font-size: 13px;
  margin-top: 3rem;
}

@media (max-width: 1024px) {  
  .grid-container.col-1-1-1 ,
  .grid-container.col-1-1,
  .grid-container.col-2-1
   {
    grid-template-columns: 1fr;
    grid-gap: 0;
    grid-auto-rows: max-content;
  }
  
}