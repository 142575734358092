@import '../css/variables.scss';

#modal {
  width:90%;
  max-width: 600px;
  max-height: 90%;
  background: #fff;
  position:absolute;
  border: 1px solid $standard-border-color;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
  padding:$vertical-spacing*2 $horizontal-spacing*2 0 $vertical-spacing*2;
  overflow-y: scroll;
}

#modal-container.active {
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.8);
  position: absolute;
  top: 0;
  left: 0;
  display:hidden;
}

#modal .button-container.pinned {
  z-index: 999;
}