/* 'BRAND' COLORS */
$primary-color: #0a3444;
$secondary-color: #567B93;
$accent-1: #328995;
$accent-2: #f7882f;
$light-neutral-1: #C8BA93;
$light-neutral-2: #ececec;
$light-neutral-3: #ccc;
$dark-neutral-1:  #404040;
$dark-neutral-2: #A59566;

$brand-primary: #338A95;
$brand-secondary: #7F9CA4;

/* UI COLORS */
$active-color: $accent-2;
$link-color:rgb(40, 109, 168);
$highlight-link-color: lighten( $link-color, 20% );
$error-color: #fc4a1a;
$success-color: #0B6F00;
$warning-color: #ffeeba;

/* FONT COLORS */
$heading-color: $dark-neutral-1;
$body-color: $dark-neutral-1;
$disabled-text-color: $dark-neutral-2;

/* BACKGROUND COLORS */
$standard-shaded-background-color: $light-neutral-1;
$highlight-background-color: lighten($active-color, 30%);
$disabled-background-color: $light-neutral-2;

/* BORDERS */
$clickable-border-color: $link-color;
$standard-border-color: $light-neutral-3;

/* PADDING & SPACING */
$vertical-spacing:  .65em; //9px;
$horizontal-spacing: .65em; //9px;

/* font-families */
$default-font: 'Archivo', sans-serif;
$copy-font: 'Georgia';
$heading-font: $default-font;
$navigation-font: $default-font;
$label-font: $default-font;

$small-breakpoint: 576px;