@import '../common/css/variables.scss';

.commish-note ul {
  margin-bottom: 1.5em;

}
.commish-note li > p {
  margin-top:0 !important;
  margin-bottom:0 !important;
}

.commish-note h2 span {
  font-size: 1.2rem;
}


.commish-note p {
  line-height: 200%;
  margin-bottom: 1.5em;
}

.commish-note small {
  font-size: 80%;
}

.commish-note em {
  font-style: italic !important;
}

.commish-note strong,.commish-note b {
  font-weight: 900 !important;
}