@import './variables.scss';

$nav-section-horizontal-padding: $horizontal-spacing*4;

#primary-nav li {
  height: 2rem;
  line-height: 2rem;
  padding: 0 $horizontal-spacing*4;
  display:block;
}

#primary-nav li:hover {
  background-color:$highlight-background-color;
}

#primary-nav li a {
  display:block;
  width:100%;
 
}

#primary-nav ul {
  margin-left: 15px;
}


.nav-section {
  padding: 0 $nav-section-horizontal-padding;
  margin-top: $vertical-spacing*2;
}



.nav-section #primary-nav {
  margin: 0 $nav-section-horizontal-padding*-1;
}

a {
  color: $link-color;
}

/*
#profile-nav {
  background-color: $dark-neutral-1;
  padding: $vertical-spacing*4 $horizontal-spacing*4;
}
*/

$user-avatar-width: 2rem;
$user-avatar-margin-left: $horizontal-spacing/2;

#profile-panel {
  background: #fff;
  overflow: hidden;
  color: #000;   
  transition: all .5s ease-in-out;
  max-height: 0;
  margin-left: calc(#{$user-avatar-width} + #{$user-avatar-margin-left})
 }
 
 .show #profile-panel {
  max-height: 500px;
  margin-bottom: $vertical-spacing*10;
}

.icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

#profile-nav .icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-family: 'Font Awesome 5 Free'; 
  content: '\f107';
  font-weight: 900; 
  margin-left: $horizontal-spacing;
  -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}

#profile-nav.show .icon::before {
  -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}