/* CONTAINERS */
@import './variables.scss';

section {
  margin-bottom: 3rem;
}

.callout {
  padding: $vertical-spacing*2 $horizontal-spacing*2;
  background-color: #fff;
  margin-bottom: $vertical-spacing*3;
  box-shadow: 3px 3px 3px #999;
}

.callout.prominent {
  background: lighten($brand-secondary,37%);
}