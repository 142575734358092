@import '../../common/css/variables.scss';

.adding-question {
  margin-top:$vertical-spacing*4;
}

.quiz-selection input {
  border: 1px solid #ececec;
}

.quiz-selection input[type="text"] {
  width: 80%;
}

textarea.quiz-question-input {
  width: 100%;
  font-size: 1rem;
  border:1px solid #ececec;
  line-height: 1.2rem;
  height: $vertical-spacing*8;
  padding:$vertical-spacing $horizontal-spacing;
}

#manage-points {
  display: table;
}

#manage-points label,
#manage-points ul {
  display: table-cell;
} 

#manage-points label {
  padding-right: $horizontal-spacing;
}

.quiz-question-actions {
  text-align:right;
}

.quiz-question-actions .btn-icon {
  display:inline-block;
  margin-left: $horizontal-spacing*2;
}