@import '../../common/css/variables.scss';

.picks-summary .team-logo {
  max-width: 80px;
}


.team-logo.selected {
  position:relative;
  border-radius: 8px;
}

.team-logo.selected:before {
  position:absolute;
  top:-18px;
  right:-18px;
  font-family: "Font Awesome 5 Free"; font-weight: 900;   
  background-color:#fff;
  font-size: 36px;  
  line-height:36px;
  border-radius: 18px;
  width:36px;
  height:36px;
  margin:0;
  padding:0;
}

.correct .team-logo.selected {
  border-color: $success-color;
}

.incorrect .team-logo.selected {
  border-color: $error-color;
}

.correct .team-logo.selected:before {
  content: "\f058";
  color:$success-color;
}

.incorrect .team-logo.selected:before {
  content: "\f057";
  color:$error-color;
}