@import '../../common/css/variables.scss';

.quiz-answer {
  margin-bottom: $vertical-spacing; 
  padding: $vertical-spacing $horizontal-spacing;
  background-color:#fff;
}

.quiz-selection {
  padding: $vertical-spacing $horizontal-spacing;
  vertical-align: middle;
  margin-top: $vertical-spacing;
  margin-bottom: $vertical-spacing;
  background-color:#fff;
}


.quiz-question-result {
  background: $error-color;
  font-size: 14px;
} 

.quiz-item .button-container {
  text-align: center;
  background-color: $dark-neutral-2;  
  padding: $vertical-spacing*2 $horizontal-spacing*2;
  margin-top: 0;
}

.quiz-item .button-container .btn.btn-link {
  margin-left: $horizontal-spacing*2;
  background-color:transparent;
  color: #fff;
}

.button-container .btn.btn-link  {
  margin-right: $horizontal-spacing*2;
  background-color:transparent;
}

ul.inline li {
  display:inline;
  margin-right:$horizontal-spacing*2;
}

section.quiz-item {
  margin-top: $vertical-spacing*3;
}


.quiz-item > div {
  margin-top: $vertical-spacing*1;
}

.quiz-item > div:first-child {
  margin-top: 0;
}


.quiz-points {
  display: flex;
}

.quiz-points > div {
  vertical-align: middle;
  width:50%;
}

.text-points {
  text-transform: uppercase;
}

p.question-text {
  font-size: 2rem;
}