@import '../css/variables.scss';


.bar-chart .bar-group {
  transition: opacity 0.3s;
}

/* Fade bars when the chart is hovered */
/*
.bar-chart:hover .bar-group {
  opacity: 0.3;
}
*/

/* But don't fade the hovered bar... */
/*.bar-chart .bar-group:hover {
  opacity: 1;
}*/

.bar-chart .bar-group:hover .name-label {
  font-weight: 400;
}

.bar-chart {
  display: table;
  table-layout: fixed;
  width:100%;
}

.bar-group {
  display: table-row;
  width:100%;
  
}

$label-width: 100px;
.bar-group label {
  display: table-cell;
  width: $label-width;
  padding-bottom: $vertical-spacing*2;
}

.bar-chart .bar-container {
  display: table-cell;
}

.bar-chart .bar {
  background-color: #348AA7;
  text-align: right;
  font-weight: 900;
  color: #fff;
  font-size:1.2em;
  padding-right:$horizontal-spacing ;
  vertical-align: middle;
  line-height: 3rem;
}

.bar-chart .bar.no-results {
  background-color: #fff;
  text-align: left;
  font-weight: 100;
  color:#777;
}

.bar-chart .title {
  font-size: 1.4em;
  font-weight: 300;
}

.bar-chart .bar-group .name-label {
  text-anchor: end;
  font-weight: 300;
  font-size: 1em;
  fill: #333;
}

.bar-chart .bar-group .value-label {
  text-anchor: end;
  fill: #fff;
  font-weight: 900;

}

.bar-chart .bar-group .value-no-results {
  font-style: italic;
  color: #999;
  font-size: .9em;
}