/* QUIZ USER SUMMARY */
@import '../../common/css/variables.scss';

#quiz-user-summary .correct:before,
#quiz-user-summary .incorrect:before {
  
  font-family: "Font Awesome 5 Free"; font-weight: 900;   
  background-color:#fff;
  font-size: 36px;  
  line-height:36px;
  border-radius: 18px;
  width:36px;
  height:36px;
  margin:0;
  padding:0;
}


#quiz-user-summary .correct:before {
  content: "\f058";
  color:$success-color;
}

#quiz-user-summary .incorrect:before {
  content: "\f057";
  color:$error-color;
}