@import './variables.scss';

table {
  width: 100%;
}

td,th {
  padding: 1rem;
}

td {
  border-left: 1px solid $standard-border-color;
  border-bottom: 1px solid $standard-border-color;
}

th {
  text-align:center;
  background-color: $dark-neutral-1;
  font-family: $heading-font;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-weight: 900;
}

td:first-child, th:first-child {
  border-left: none;
}

tr:nth-child(odd) td {
  background-color: #fff;
}

th.border-override:first-child {
  border-left: 1px solid #fff;

}

.table .thead-dark th.grouped-alt,th.grouped-alt {
  background-color: lighten(#343a40,10%)
}


